import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCEyyRtppSNkOWl17-hCSk-VOxQBAoYO1U",
    authDomain: "conekuta-lp-demo.firebaseapp.com",
    projectId: "conekuta-lp-demo",
    storageBucket: "conekuta-lp-demo.appspot.com",
    messagingSenderId: "153800277344",
    appId: "1:153800277344:web:6e05495967a0d8851eb205",
    measurementId: "G-J3HH6BM6CZ"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore();
export default app;

