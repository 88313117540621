import * as React from "react";
import { Box } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Faq = () => {
  return (
    <Box
    // py={1}
    sx={{
      // backgroundColor: "rgba(220,220,220,0.7)",
      minHeight: "auto",
    }}
    >

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            月額費以外に費用はかかりますか？
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. 初期費用/導入費は0円で、月額費以外に費用はかかりません。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            無料トライアル登録以外に、お店側での手続きなどは必要ですか？
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. 特にありません。無料トライアル登録後すぐにご利用を開始いただけます。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            最低利用期間はありますか？
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. 特にありません。面倒な手続きも無しでいつでもご退会が可能です。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ boxShadow: 'none', '&:before': { display: 'none' }, '&.Mui-expanded': { margin: 0 } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            sx={{
              color: "#fdda78",
              fontWeight: "bold",
              paddingRight:"0.5rem"
            }}
          >
            Q.
          </Typography>
          <Typography
            textAlign={"left"}
            sx={{
              // color: "#fdda78",
              fontWeight: "bold",
            }}
            >
            スマホとパソコンどちらでも利用できますか？
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            A. 基本的にはどちらでも利用できます。QRコードを読み取る時にカメラを使用するので。スマホでのご利用を推奨しています。
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

export default Faq;