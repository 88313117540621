import React, { useState } from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// ダイアログ
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


// Firebase
// dbをimportする(この後の例では省略する)
import { db } from "./firebaseConfig";
// FireStoreのAPI(この後の例では省略する)
import {collection, addDoc} from "firebase/firestore";



const Feedback = () => {
  //メールアドレスフォーマットチェック用正規表現定義
  const regexpEmail = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  
  //見た目の設定
  const theme = createTheme({
    typography: {
      fontFamily: "inherit",
      button: {
        textTransform: "none",
        fontFamily: "inherit"
      }
    }
  });

  const textfieldStyles = {
    backgroundColor: "#ffffff",
    fontFamily: "inherit"
  };

  const textlabelStyles = {
    fontFamily: "inherit"
  };

  //state定義
  const [values, setValues] = useState(
    {
      fbname: "",
      fbemail: "",
      fbmessage: "",
      isSubmitted: false
    }
  );

  //文字入力の度にstate更新
  // 共通処理
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // 「...(スプレッド構文)」でオブジェクトの要素を展開
    // 同じプロパティ名がある場合は値を更新する。無ければ、プロパティを追加する。
    // setは非同期処理
    // setStateで値が更新されるのは関数が呼び出された後
    setValues({ ...values, [name]: value });
  };


  const handleSubmit = async (e) => {
    // イベントのデフォルトの処理をキャンセルする
    // 　→今回で言うと、submitの処理をキャンセルしている
    e.preventDefault();

    try {
      // Firestoreにお問い合わせ情報を保存
      await addDoc(collection(db, "mail"), {
        to: 'info@conekuta.com',
        // to: process.env.REACT_APP_CONTACT_EMAIL,
        from: values.fbemail,
        message: {
          subject: values.fbname,
          text: values.fbmessage,
        },
      });

      console.log('お問い合わせが送信されました');
      // 送信完了のメッセージを表示などの追加処理を行う

      // フォームをリセット
      setValues({
        fbname: "",
        fbemail: "",
        fbmessage: ""
      });

      // Dialogを表示する
      setOpen(true);

    } catch (error) {
      console.error('お問い合わせの送信エラー:', error);
      // TODO:エラーメッセージの表示などの追加処理を行う
    }
  };

  // Dialog用-------------------------------------
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  // ---------------------------------------------

  return (
    <div>

      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  お問い合わせいただき、ありがとうございます。
                  <br/>回答を送付いたしますので、少々お待ちください。
              </DialogContentText>
          </DialogContent>

          <DialogActions>
              <Button onClick={handleClose} autoFocus>
                  閉じる
              </Button>
          </DialogActions>
      </Dialog>

      {/* <Box component="form" noValidate autoComplete="off"> */}
      <Box
        component="form" 
        noValidate 
        autoComplete="off"
        sx={{
          display: 'flex',
          flexDirection: 'column', // 子要素を縦に並べる
          alignItems: 'center', // 子要素を中央寄せにする
          width: '100%', // Boxの幅を100%に設定
        }}
      >
        <ThemeProvider theme={theme}>
          {/* <FormControl fullWidth> */}
          <FormControl sx={{ width: '90%' }}>
          {/* <FormControl  fullWidth sx={{ marginX: 2, maxWidth: '90%', mx: 'auto' }}> */}
            <TextField
              name="fbname"
              id="fbname"
              label="お名前"
              placeholder=""
              variant="filled"
              margin="dense"
              size="small"
              InputProps={{ style: textfieldStyles }}
              InputLabelProps={{ style: textlabelStyles }}
              value={values.fbname}
              onChange={handleChange}
            />
            <TextField
              name="fbemail"
              id="fbemail"
              label="メールアドレス"
              placeholder="◯◯@gmail.com"
              variant="filled"
              margin="dense"
              size="small"
              InputProps={{ style: textfieldStyles }}
              InputLabelProps={{ style: textlabelStyles }}
              value={values.fbemail}
              onChange={handleChange}
              error={((regexpEmail.test(values.email) && (values.email)) || !(values.email)) ? false : true}
              helperText={((regexpEmail.test(values.fbemail) && (values.fbemail)) || !(values.fbemail)) ? null : "メールアドレスを入力してください。"}
            />
            <TextField
              name="fbmessage"
              id="fbmessage"
              label="ご質問・ご意見"
              multiline
              minRows={3}
              placeholder=""
              variant="filled"
              margin="dense"
              size="small"
              InputProps={{ style: textfieldStyles }}
              InputLabelProps={{ style: textlabelStyles }}
              value={values.fbmessage}
              onChange={handleChange}
            />
          </FormControl>
          <br />
          <br />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
              variant="contained"
              size="large"
              endIcon={<SendIcon />}
              disabled={(values.fbname && regexpEmail.test(values.fbemail) && values.fbmessage) ? false : true}
              onClick={handleSubmit}
            >
              送信
            </Button>
          </div>
        </ThemeProvider>
      </Box>
    </div>
  );
};

export default Feedback;